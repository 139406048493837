
import { api_base_url } from '../../../../../global_data';
import axios from "axios";


const setInitialData = async ({ setState, itemId }) => {
    
    const res = await axios({
        url: `${api_base_url}/deckItems/${itemId}`,
        method: 'GET'
    });

    const data = res.data;
    
    setState(data);

}

export default setInitialData;