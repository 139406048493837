
import React, { useContext, useEffect } from "react";
import useSetState from '../../../utils/useSetState';
import UpdateItem from '../../myItems/UpdateItem';
import onSubmitUpdatedItem from './utils/onSubmitUpdatedItem';
import { UserContext } from '../../../Context/UserContext/UserContext';
import { FeedbackContext } from '../../../Context/FeedbackContext/FeedbackContext';
import setInitialData from './utils/setInitialData';
import Navbar from "../../../lib/components/Navbar/Navbar";
import CenterContainer from "../../../lib/components/CenterContainer/CenterContainer";
import DashboardWrapper from "../../DashboardWrapper";
import queryString from 'query-string';
import { cloneDeep } from 'lodash';


const UpdateDeckContainer = ({ location }) => {

    const [state, setState] = useSetState(null);

    const { userGlobalState } = useContext(UserContext);
    const { setFeedbackState } = useContext(FeedbackContext);

    const itemId = queryString.parse(location.search).itemId;
    const deckName = queryString.parse(location.search).deckName;
    const deckId = queryString.parse(location.search).deckId;
    const artistName = queryString.parse(location.search).by;

    useEffect(() => {      
        setInitialData({ setState, itemId });
      }, [])    

    const onSubmit = (e) => {
        e.preventDefault();
        onSubmitUpdatedItem({ state, userGlobalState, setFeedbackState, deckName, deckId, artistName, userGlobalState})
    } 
    
    const onChangeField = ({ fieldName }, e) => {
        const stateClone = cloneDeep(state);
        stateClone[fieldName] = e.target.value;
        setState(stateClone);
    }


    return (
        <>
            <Navbar/>
                <CenterContainer>
                    <DashboardWrapper>
                        <UpdateItem 
                            onSubmit={onSubmit} 
                            onChangeField={onChangeField} 
                            userGlobalState={userGlobalState}
                            data={state}
                        />
                    </DashboardWrapper>
                </CenterContainer>
           
        </>
    );
};

export default UpdateDeckContainer;
