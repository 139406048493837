
import React from "react";
import UpdateItemContainer from '../../../Dashboard/myItems/containers/UpdateItemContainer'


const CreateItemPage = ({ location }) => {

  return (
    <UpdateItemContainer location={location}/>
  )
}

export default CreateItemPage;
