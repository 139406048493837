import React from 'react';
import PageHeader from '../../lib/components/PageHeader/PageHeader';
import MyItemForm from '../components/MyItemForm';
import Breadcrumbs from '../../lib/components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';

const UpdateDeck = ({ onSubmit, onChangeField, userGlobalState, data }) => {
  return (
    <>
     <Helmet>
        <title>Update Deck Item</title>
      </Helmet>
      {data ? (
        <>
          <PageHeader>
            <Breadcrumbs
              crumbsArr={[
                {
                  title: 'My Decks',
                  to: `/dashboard/myDecks?artistId=${userGlobalState.artist._id}`
                },
                { title: data.title }
              ]}
            />
          </PageHeader>
          <MyItemForm
            value={data}
            onSubmit={onSubmit}
            onChangeField={onChangeField}
            cta="Save"
          />
        </>
      ) : (
        '...Loading'
      )}
    </>
  );
};

export default UpdateDeck;
